/**
 *
 * About Page
 */
import { Link as RouterLink } from "react-router-dom";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import SocialShare from "../Social/SocialShare";

import headshot from '../assets/headshot-founder-amy.png';
import InstagramLink from "../shared/InstagramLink";

export default function AboutPage() {
  const shareURL = window.location.protocol + '//' + window.location.host;
  const shareTitle = 'Donatic: Give things, not money. Donate with us today.';
  const shareEmailSubject = 'Checkout Donatic, a platform for donating (new) items.';
  const shareEmailBody = 'Donatic is a platform where fundraisers can create campaigns to receive items in new condition. Check it out!';

  return (
    <Container sx={{ pt: 4 }}>
      <Grid container columnSpacing={5}>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography component="h2" variant="h5">
              Company
            </Typography>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Typography paragraph sx={{ typography: "body1" }}>
              Donatic App Inc. is a donation platform that provides full transparency to donors and empowers them to decide kind of impact they want to have. We do this by enabling anyone in need of donation items to list these physical goods on our platform for free, and then when someone donates, we handle purchasing and delivering of the items.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }} color="primary">
              <u>
                Our goal is to give you, the donor,{" "}
                <strong>
                  the power to choose exactly where your money goes
                </strong>
                .
              </u>
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              We do this by allowing approved nonprofits and fundraisers to create a campaign for physical items rather than monetary donations.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              We charge a
              service (convenience) fee of 5%. This means that when you donate $100.00's worth of items, it costs $5.00 for us to purchase and deliver those items
              on your behalf.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              While we highly encourage you to donate items directly yourself, we exist to handle this for you, for
              those times when it is not possible or convenient. This fee covers our operating costs.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }} color="primary">
              This also <u>streamlines the process</u> for any fundraisers and
              non-profits, shipping items directly to them when donors donate
              through out platform.{" "}
              <strong>
                Donatic is always free for nonprofits and individuals that are collecting supplies on our platform
              </strong>
              .
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              Instead of trying to compete with monetary donations, we are offering a new channel to accept donations via our service.
            </Typography>

            <Typography paragraph sx={{ typography: "body1" }}>
              Please{" "}
              <Link component={RouterLink} color="secondary" to="/contact">
                contact us
              </Link>{" "}
              at any time with any concerns or feedback you may have, we'd love
              to hear from you.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} textAlign="center">
          <img src={headshot} alt="logo" width="200px" height="200px" max-width="100%" />
          <Typography sx={{ mt: 2 }} paragraph align="left">
            "The goal of Donatic is to provide donors with transparency, while streamlining the process of item collection for fundraisers. We try our best to create a win-win situation while driving a positive impact."
          </Typography>
          <Typography paragraph align="right">
            -- Amy Soetopo, Founder
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph>
            Enjoying Donatic? Help us spread the word!
          </Typography>
          <SocialShare url={shareURL} title={shareTitle} quote={shareEmailBody} emailSubject={shareEmailSubject} emailBody={shareEmailBody}  />

          <Typography paragraph>You can also follow us to stay up-to-date:</Typography>

          <InstagramLink />
      </Grid>
    </Grid>
    </Container>
  );
}
